exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-embedded-planungstool-jsx": () => import("./../../../src/pages/embedded-planungstool.jsx" /* webpackChunkName: "component---src-pages-embedded-planungstool-jsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-card-jsx": () => import("./../../../src/templates/card.jsx" /* webpackChunkName: "component---src-templates-card-jsx" */),
  "component---src-templates-homepage-jsx": () => import("./../../../src/templates/homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

